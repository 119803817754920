.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiChip-colorPrimary {
  color: #231f20 !important;
  background-color: #FFFFFF !important;
}

.MuiChip-root .MuiChip-avatarColorPrimary,
.MuiButton-containedPrimary {
  background: linear-gradient(315deg, #ffd200 18.29%, #FFF229 83.33%);
  color: #231f20 !important;
  -webkit-box-shadow: 0 4px 12px rgba(204, 168, 0, 0.32) !important;
  box-shadow: 0 4px 12px rgba(204, 168, 0, 0.32) !important;
}


.MuiFormLabel-root.Mui-focused {
  color: #000 !important;
}

.Mui-focused fieldset {
  border: 1px solid #FFD200 !important;
}

.MuiFormHelperText-root {
  color: #f44336;
  height: 3px;
}

.MuiAppBar-colorPrimary {
  background-color: #1A1A1A !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0.5px rgba(0,0,0,0.12), 0px 0px 0px 0px rgba(0,0,0,0.12) !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: rgb(255 242 41);
  border-color: rgb(255 242 41);
  color: #000;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  /* display: none; */
  box-shadow: -10px 0 0 rgb(255 242 41);
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
  margin-right: 0 !important;
}